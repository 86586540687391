import React from 'react';
import Layout from "../../../components/layout";
import {StaticImage} from "gatsby-plugin-image";

class SpiritedTraditionalOkinawanGojuMeet extends React.Component {
    render() {
        return (
            <Layout>
                <h1>Spirited Traditional Okinawan Goju meet - Blitz Magazine, Spring 1989</h1>
                <StaticImage src="../../../images/spiritedtraditional/ResizedImage600825-1648497.jpg"
                             alt="Spirited Traditional Okinawan Goju meet"/>
            </Layout>);
    }
}

export default SpiritedTraditionalOkinawanGojuMeet;
